<template>
	<div>
		<div id="banner" v-if="isBannerDataAvailable">
			<div
				v-if="isLoadingBanner && isBannerDataAvailable"
				class="banner-loading"
			>
				<div class="banner-spinner"></div>
			</div>

			<Banner
				:class="{ 'banner-is-hidden': isLoadingBanner }"
				:categoryType="sliderType"
				@loaded="handleBannerLoaded"
				@update:bannerStatus="handleBannerStatus"
			/>
		</div>
		<div class="container">
			<div style="margin-top: 10%">
				<schedule-table />
			</div>
		</div>
	</div>
</template>

<script>
import ScheduleCard from "../Components/NLSE/Schedule/ScheduleTable.vue";
import Banner from "../Components/NLSE/Slider/BannerRedesigned.vue";
export default {
	name: "WatchPage",
	data() {
		return {
			sliderType: "WhatsOn",
			isBannerDataAvailable: true,
			isLoadingBanner: true,
		};
	},
	components: {
		"schedule-table": ScheduleCard,
		Banner,
	},
	methods: {
		handleBannerStatus(hasData) {
			this.isBannerDataAvailable = hasData;
		},
		handleBannerLoaded() {
			this.isLoadingBanner = false; // Set to false when Banner is loaded
		},
	},
};
</script>

<style scoped></style>
